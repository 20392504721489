const nav_data = {
    state: {
        isCollapse: false,
        // 路由
        nav: [],
        // 左侧导航
        leftNav: []
    },
    mutations: {
        CHANGE_COLLAPSE (state) {
            state.isCollapse = !state.isCollapse;
        },
        async SET_NAV (state, data) {
            state.nav = data
            state.leftNav = handleRouter(await test(data))
            // 所有路由
            // state.leftNav = handleRouter(data)
        },
        EMPTY_NAV (state) {
            // state.nav = []
            state.leftNav = []
        }
    },
    actions: {
        CHANGE_COLLAPSE ({ commit }) {
            commit('CHANGE_COLLAPSE')
        },
        SET_NAV ({ commit }, data) {
            commit('SET_NAV', data)
        }
    },
}

export default nav_data

import { filterRouter } from '@/utils/filterRouter.js'
import { getRole } from '@/api/auth.js'

function test (data) {
    return new Promise((resolve, reject) => {
        let testRouter
        getRole().then(res => {
            console.log(res.data.urlList);
            if (res.success) {
                testRouter = filterRouter(res.data.urlList, data)
                // handleRouter(testRouter)
                resolve(testRouter)
            }
        })
    })

}

// 接受到的路由进行处理 进行左边菜单栏渲染
function handleRouter (data) {
    let res = []
    let navData = JSON.stringify(data)
    navData = JSON.parse(navData)
    navData.forEach(val => {
        let tmp = {}
        if (!val.meta.hideNav) {
            val.path && (tmp.path = val.path);
            val.meta.LoginRoad && (tmp.LoginRoad = val.meta.LoginRoad);
            !val.meta.LoginRoad && (tmp.LoginRoad = val.meta.title);
            val.meta.title && (tmp.title = val.meta.title);
            val.meta.icon && (tmp.icon = val.meta.icon);
            val.children && (tmp.children = handleRouter(val.children));
            res.push(tmp)
        }
    })
    return res
}
