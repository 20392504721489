// import allRouter from '@/router/modules.js'
// import store from '@/store'


// import { getRole } from '@/api/auth.js'


export function filterRouter (resRouter, allRouters) {
    // console.log(resRouter, allRouters);
    let newRouter = []
    allRouters.forEach(item => {
        resRouter.forEach(all => {
            // if (item.routeName == all.routeName){
            //     let obj = JSON.parse(JSON.stringify(all))
            //     // // 将obj的子清除
            //     // obj.children = undefined;
            //     if(item.children && item.children.length > 0){
            //         let childrenRouter = filterRouter(item.children,all.children)
            //         obj.children = childrenRouter
            //     }
            //     newRouter.push(obj)
            // }
            if (item.path == all.url) {
                item.meta.hideNav = true
                if (all.checkedStatus == 2) all.hasChecked = true
                item.meta.hideNav = !all.hasChecked
                item.meta.title = all.name
                item.meta.LoginRoad = all.name
                let obj = JSON.parse(JSON.stringify(item))
                // 将obj的子清除
                obj.children = undefined;
                if (item.children && item.children.length > 0) {
                    let childrenRouter = filterRouter(all.childrenAuthUrl, item.children)
                    obj.children = childrenRouter
                }
                newRouter.push(obj)
            }
        })
    })
    return newRouter
}
