// 系统管理

import LayoutMain from '@/layout/LayoutMain'

const systemManagement = {
  path: '/system',
  component: LayoutMain,
  redirect: '/system/systemManagement',
  meta: {
    title: '系统管理',
    icon: 'el-icon-s-platform',
    // 临时
    // hideNav: true,
  },
  children: [
    {
      path: '/system/disposition',
      name: 'disposition',
      meta: {
        title: '平台配置',
        // 临时
        // hideNav: true,
      },
      component: () => import('@/views/system/disposition.vue'),
    },
    {
      path: '/system/deviceManage',
      name: 'deviceManage',
      meta: {
        title: '设备管理',
        // 临时
        // hideNav: true,
      },
      component: () => import('@/views/system/deviceManage.vue'),
    },
    {
      path: '/system/mediaManage',
      name: 'operationLog',
      meta: {
        title: '媒体管理',
        // 临时
        // hideNav: true,
      },
      component: () => import('@/views/system/mediaManage.vue'),
    },
    {
      path: '/system/operationLog',
      name: 'operationLog',
      meta: {
        title: '操作日志',
        // 临时
        // hideNav: true,
      },
      component: () => import('@/views/system/operationLog.vue'),
    },
    {
      path: '/system/warnManage',
      name: 'warnManage',
      meta: {
        title: '成绩明细',
        // 临时
        hideNav: true,
      },
      component: () => import('@/views/system/warnManage.vue'),
    },
  ],
}

export default systemManagement
